import { BroadcastChannel } from 'broadcast-channel';
import React, { useEffect, useMemo } from 'react';
import AppConfig from './services/configservice';

const RELAY_CHANNEL_NAME = 'relay';

// NOTE: must match the definition in authzed-common/src/services/relayservice.ts. authzed-common is not
// imported here to minimize the bundle size.
interface RelayMessage {
    message: { kind: string }
    targetOrigins: string[]
}

const origins = new Set(AppConfig().relayDomains.map((relayDomain: string) => {
    return new URL(relayDomain).origin;
}));

export function App(props: { forTesting?: boolean }) {
    const channel = useMemo(() => {
        return props.forTesting ? null : new BroadcastChannel(RELAY_CHANNEL_NAME);
    }, [props.forTesting]);

    useEffect(() => {
        const parent = window.parent;
        if (!channel) {
            return
        }

        const listener = function (msg: RelayMessage) {
            if (msg.targetOrigins) {
                const targetOrigins = new Set(msg.targetOrigins);
                console.log('Raising incoming message to parent', {
                    targetOrigins: msg.targetOrigins,
                    kind: msg.message.kind,
                })

                // Post to each of the target origin(s), which should ensure at least
                // one call succeeds.
                Array.from(targetOrigins).forEach((targetOrigin: string) => {
                    parent.postMessage(msg, targetOrigin);
                })
            }
        };

        channel.addEventListener('message', listener);

        return () => {
            channel.removeEventListener('message', listener);
        };
    }, [channel]);

    useEffect(() => {
        if (!channel) {
            return
        }

        const listener = function (event: MessageEvent<any>) {
            console.log('Got incoming message', {
                origin: event.origin,
            })

            if (!origins.has(event.origin)) {
                return
            }

            const msg = event.data as RelayMessage;
            if (msg.targetOrigins) {
                const intersect = new Set([...msg.targetOrigins].filter(i => origins.has(i)));
                if (intersect.size > 0) {
                    console.log('Posting incoming message to channel', {
                        targetOrigins: msg.targetOrigins,
                        kind: msg.message.kind,
                    })

                    channel.postMessage(msg);
                }
            }
        };
        window.addEventListener('message', listener);

        return () => {
            window.removeEventListener('message', listener);
        };
    }, [channel]);

    return <div>Ready</div>;
}