import { env } from 'process';
import * as config from '../config.json';

/**
 * Configuration that we get through an environment js file.
 */
interface EnvConfig {
    RELAY_COMMA_SEP_DOMAINS?: string
}

declare global {
    interface Window { _env_?: EnvConfig; }
}

interface ApplicationConfig {
    relayDomains: string[]
}

/**
 * AppConfig returns the ApplicationConfig.
 */
export default function AppConfig(): ApplicationConfig {
    let typed = {
        relayDomains: config.relayDomains as string[],
    };

    // Environment variable overrides.
    if (window._env_) {
        if (window._env_.RELAY_COMMA_SEP_DOMAINS) {
            typed.relayDomains = window._env_.RELAY_COMMA_SEP_DOMAINS?.split(',') ?? []
        }
    }

    if (env.NODE_ENV === 'test') {
        return typed;
    }

    if (!typed.relayDomains.length) {
        throw Error('Missing Relay Domains')
    }

    return typed
}